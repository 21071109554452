import { useCallback, useEffect, useState } from "react";
import { WordMark } from "./Brand";
import { HighlightedButton } from "./Button";
import { Container } from "./Container";
import clsx from "clsx";

const navLinkFocusStyle =
  "focus:outline-none focus-visible:ring-2 ring-indigo-500 hover:bg-slate-600 hover:bg-opacity-25 transition-all border border-transparent hover:border-slate-500 hover:text-white focus:text-white";

const NavLink = ({
  children,
  href = "#",
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children: React.ReactNode;
  href?: string;
}) => {
  return (
    <a
      {...props}
      href={href}
      className={`text-slate-100 px-3 py-3 mb-3 md:mb-0 text-sm  font-medium self-stretch items-center rounded-full flex ${navLinkFocusStyle} ${
        props.className || ""
      }`}
    >
      {children}
    </a>
  );
};

const NavLinks = () => {
  return (
    <>
      <NavLink href="/features">Features</NavLink>
      <NavLink
        target="_blank"
        href="https://codemuse.notion.site/a09cd839084048b0bf49dcd98540d01b?v=3cbf6b9c75fe431aa54927ca0ee7b584"
      >
        Docs
      </NavLink>
      <NavLink href="/pricing">Pricing</NavLink>
      <span className="hidden md:block flex-grow"></span>
      <HighlightedButton className="text-sm mr-px" as="a" href="/get-involved">
        Get involved
      </HighlightedButton>
    </>
  );
};

export const Nav = () => {
  // Detect when the user scrolls the page down 100px from the top, and add a class to the nav to make it opaque
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMobileNav = useCallback(() => {
    // Disable scrolling on the body when the mobile nav is open
    if (isOpen) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    const handler = () => {
      if (isOpen) {
        toggleMobileNav();
      }
    };

    document.addEventListener("astro:after-swap", handler);

    return () => {
      document.removeEventListener("astro:after-swap", handler);
    };
  }, [isOpen, toggleMobileNav]);

  // Use the useEffect hook to add an event listener to the window object
  // When the user scrolls, check if the scroll position is greater than 100px
  // If it is, set the state to true

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <nav
        className={`fixed transition-all ${
          isScrolled ? "-top-px" : "top-4 md:top-6"
        } left-0 right-0 z-50`}
      >
        <Container>
          <div
            className={clsx(
              `h-[3.5rem] z-50 relative box-border border transition-all`,
              isScrolled && "rounded-t-none rounded-b-[1.75rem]",
              "bg-slate-900",
              "border-slate-700 border-t-slate-600 rounded-[1.75rem]  flex items-center justify-between p-1 backdrop-blur-md shadow shadow-slate-900 bg-opacity-50"
            )}
          >
            <a
              href="/"
              className={`group pr-5 hover:text-white text-white font-medium flex items-center self-stretch rounded-full ${navLinkFocusStyle}`}
            >
              <div className="self-stretch relative">
                <div className="h-full -mt-8 translate-y-[1px] rounded-bl-[calc(100%/2-1rem)] overflow-hidden pl-2 flex  relative box-content pt-8 pb-1 pr-3">
                  <div className="w-[4.5rem] transition-transform translate-y-2 group-hover:translate-y-1 group-focus:translate-y-1 relative">
                    <img
                      src="images/logo-cutout-sm.png"
                      className="absolute bottom-0 blur-sm opacity-30 group-hover:opacity-100 group-hover:blur group-focus:blur group-focus:opacity-100 transition-all"
                    />
                    <img
                      src="images/logo-cutout-sm.png"
                      className="absolute bottom-0"
                    />
                  </div>
                </div>
              </div>
              <WordMark className="h-4 fill-current" />
            </a>
            <span className="md:hidden flex-grow"></span>
            <button
              type="button"
              className="h-12 w-12 md:hidden flex items-center justify-center rounded-md p-2.5 text-slate-500"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
              onClick={toggleMobileNav}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
            <div className="hidden md:flex flex-grow items-center">
              <NavLinks />
            </div>
          </div>
        </Container>
      </nav>
      <div
        className={clsx(
          "md:hidden",
          "p-6 overflow-y-auto z-40",
          "bg-slate-800 bg-opacity-75 backdrop-blur-md",
          isOpen ? "block" : "hidden",
          "fixed top-0 left-0 right-0 h-screen",
          isScrolled ? "pt-20" : "pt-24"
        )}
      >
        <NavLinks />
      </div>
      <div className="h-24"></div>
    </>
  );
};

import clsx from "clsx";
import "./button.css";

export const HighlightedButton = <T extends React.ElementType>({
  children,
  animated = false,
  className = "",
  as,
  noPad = false,
  noRound = false,
  ...props
}: {
  children: React.ReactNode;
  animated?: boolean;
  className?: string;
  noPad?: boolean;
  noRound?: boolean;
  // As accepts any valid HTML tag, or a React component
  as?: T;
  // Accept any remaining props if they are valid for the component passed to as
} & React.ComponentPropsWithoutRef<T>) => {
  const Component = as || "button";

  return (
    <Component
      className={clsx(
        "group inline-flex relative highlighted",
        animated && "animated",
        "bg-clip-padding bg-slate-900 border-2 border-transparent text-white items-center  box-border shadow cursor-pointer",
        noPad ? "p-0" : "px-3 py-2",
        noRound ? "" : "rounded-full",
        className
      )}
      {...props}
    >
      <div
        className={clsx(
          "absolute -top-0 -m-[2px] left-0 right-0 bottom-0 highlighted-ring -z-10 pointer-events-none",
          noRound ? "" : "rounded-full"
        )}
      ></div>
      <div className="flex gap-4 items-center">{children}</div>
    </Component>
  );
};

export const Button = <T extends React.ElementType>({
  type,
  as,
  children,
  ...props
}: React.ComponentPropsWithoutRef<T> & {
  type: "primary" | "secondary";
  as?: T;
}) => {
  const Component = as || "button";

  return (
    <Component
      {...props}
      className={clsx(
        "select-none cursor-pointer px-5 flex gap-2 justify-center items-center py-3 rounded-md font-medium transition-all duration-75 focus:ring-2 focus:outline-none",
        type === "primary" &&
          "bg-indigo-500 hover:bg-indigo-400 text-white focus:ring-indigo-300",
        type === "secondary" &&
          "bg-slate-600 hover:bg-slate-500 text-white focus:ring-slate-400",
        props.className
      )}
    >
      {children}
    </Component>
  );
};

export const WordMark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 63 10"
      version="1.1"
      viewBox="0 0 63 10"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M.61 6.19c0-1.82 1.42-3.2 3.29-3.2 1.63 0 2.9 1.04 3.14 2.49H5.39c-.21-.59-.78-.98-1.49-.98-.96 0-1.68.73-1.68 1.69s.72 1.69 1.68 1.69c.71 0 1.28-.4 1.49-.98h1.66C6.8 8.35 5.53 9.39 3.9 9.39 2.03 9.39.61 8.01.61 6.19zM7.78 6.19c0-1.82 1.42-3.2 3.29-3.2s3.29 1.38 3.29 3.2c0 1.82-1.42 3.2-3.29 3.2-1.88 0-3.29-1.38-3.29-3.2zm4.96 0c0-.96-.71-1.69-1.68-1.69-.96 0-1.68.73-1.68 1.69s.72 1.69 1.68 1.69c.98 0 1.68-.73 1.68-1.69zM15.12 6.19c0-1.82 1.28-3.2 2.99-3.2.82 0 1.5.32 1.99.86V.61h1.56v6.61c0 .32.18.52.52.52h.12v1.51h-.44c-.84 0-1.38-.3-1.62-.89-.5.63-1.24 1.03-2.13 1.03-1.7 0-2.99-1.38-2.99-3.2zm4.98 0c0-.96-.72-1.69-1.69-1.69-.96 0-1.68.73-1.68 1.69s.72 1.69 1.68 1.69c.97 0 1.69-.73 1.69-1.69zM22.92 6.19c0-1.82 1.42-3.2 3.29-3.2S29.5 4.37 29.5 6.2c0 .16-.01.38-.05.53h-4.86c.2.79.83 1.31 1.67 1.31.64 0 1.19-.29 1.45-.68h1.69c-.47 1.21-1.68 2.04-3.17 2.04-1.89-.01-3.31-1.39-3.31-3.21zm1.68-.6h3.21c-.19-.75-.81-1.25-1.6-1.25s-1.39.49-1.61 1.25zM30.59 3.13h1.56v.64c.46-.52 1.07-.78 1.81-.78.88 0 1.57.4 1.94 1.06.48-.68 1.2-1.06 2.1-1.06 1.34 0 2.24.9 2.24 2.28v1.96c0 .32.19.52.52.52h.13v1.51h-.44c-1.16 0-1.76-.58-1.76-1.69V5.73c0-.74-.47-1.24-1.2-1.24-.77 0-1.28.5-1.28 1.26v3.49h-1.56V5.73c0-.74-.48-1.24-1.2-1.24-.77 0-1.3.5-1.3 1.26v3.49h-1.56V3.13zM41.64 7.05V3.13h1.56V6.6c0 .78.5 1.28 1.26 1.28.8 0 1.34-.52 1.34-1.31V3.13h1.56v4.09c0 .32.19.52.52.52H48v1.51h-.44c-.82 0-1.36-.29-1.61-.85-.47.65-1.15 1-2 1-1.39-.01-2.31-.93-2.31-2.35zM51.28 7.58c.3.19.66.3 1.04.3.73 0 1.22-.4 1.22-1.02 0-.88-1.16-1.15-1.74-2.11l-1.9 4.5h-1.6l2.64-6.12h1.79c.27 1.58 2.42 1.56 2.42 3.77 0 1.48-.96 2.49-2.53 2.49-.71 0-1.35-.21-1.87-.56l.53-1.25zM55.81 6.19c0-1.82 1.42-3.2 3.29-3.2s3.29 1.38 3.29 3.21c0 .16-.01.38-.05.53h-4.86c.2.79.83 1.31 1.67 1.31.64 0 1.19-.29 1.45-.68h1.69c-.47 1.21-1.68 2.04-3.17 2.04-1.89-.01-3.31-1.39-3.31-3.21zm1.68-.6h3.21c-.19-.74-.82-1.25-1.61-1.25-.78 0-1.38.49-1.6 1.25z"></path>
    </svg>
  );
};
